<template>
  <BCard
    v-if="authorsAll.length > 1"
    header-tag="h2"
    header-bg-variant="ams-gray"
    header-border-variant="ams-header-orange"
    header-text-variant="black"
    header-class="border-bottom highlight-border"
    align="left"
    class="m3 shadow mt-3"
  >
    <template slot="header">
      Top Authors
    </template>
    <BRow>
      <BCol>
        <BTabs
          v-if="authorsAll.length > 0"
          style="width:100%;"
        >
          <BTab
            v-if="authors3yr.length > 1"
            title="Last 3 Years"
          >
            <Msntable
              :rows="authors3yr"
              :header="true"
              :limit="authorsShownLimit ? defaultCount : -1"
            />
          </BTab>
          <BTab
            v-if="authorsAll.length > 1"
            title="All Time"
          >
            <Msntable
              :rows="authorsAll"
              :header="true"
              :limit="authorsShownLimit ? defaultCount : -1"
            />
          </BTab>
        </BTabs>
        <div v-else>
          <i>No authors found for this journal</i>
        </div>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BButton
          v-if="authorsAll.length > defaultCount"
          class="btn-ams-blue-dark indent-xs"
          @click="toggleAuthorsTable()"
          :title="authorsShownLimit ? 'Show all authors' : 'Show fewer authors'"
          :name="authorsShownLimit ? 'Show all authors button' : 'Show fewer authors button'"
        >
          <span v-if="authorsShownLimit">
            Show All
          </span>
          <span v-else>
            Show Less
          </span>
        </BButton>
      </BCol>
    </BRow>
  </BCard>
</template>
<script>
import { formatName, formatNumber } from '@/utils'
// import URLGen from '@/utils/url-gen'
import { JournalAPI, SeriesAPI } from '@/js-api'

export default {
  name: 'ProfileAuthorsComponent',
  components: {
    Msntable: () => import(/* webpackChunkName: "journal_msntable" */ '@/components/journal/MsnTable.vue'),
  },
  // title: 'ProfileAuthors',
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    seriesId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // Authors
      authorsAll: [
        ['Name', 'Papers'],
      ],
      authors3yr: [
        ['Name', 'Papers'],
      ],
      authorsShownLimit: true,
      defaultCount: 10,
    }
  },
  computed: {
    yearRange: function() {
      const today = new Date()
      const endYear = today.getFullYear()
      const startYear = endYear - 3
      return [startYear, endYear]
    },
    isSeries: function() {
      return (this.seriesId && this.seriesId > -1)
    },
  },
  mounted: function() {
    this.getAuthors()
  },
  methods: {
    getAuthors: async function() {
      let result
      if (this.isSeries) {
        result = await SeriesAPI.authors(this.seriesId)
      } else {
        result = await JournalAPI.authors(this.groupId)
      }

      const topAuthors = {}
      if (this.isSeries) {
        if (result.results.length === 0) {
          // No authors...
          return
        }
        topAuthors.lastThreeYears = result.results[0].topAuthors.lastThreeYears
        topAuthors.allTime = result.results[0].topAuthors.allTime
      } else {
        if (result.length === 0) {
          // No authors...
          return
        }
        topAuthors.lastThreeYears = result[0].topAuthors.lastThreeYears
        topAuthors.allTime = result[0].topAuthors.allTime
      }

      this.authors3yr = this.authors3yr.concat(this.buildAuthorsTable(topAuthors.lastThreeYears, true))
      this.authorsAll = this.authorsAll.concat(this.buildAuthorsTable(topAuthors.allTime, false))
    },
    buildAuthorsTable: function(data, yrRange) {
      // let yearRange = this.tableYrRange(yrRange)
      let publicationsQuery = this.isSeries ? `si:${this.seriesId} ` : `gi:${this.groupId} `
      const pubRequestInfo = {}
      if (this.isSeries) {
        pubRequestInfo.seriesId = this.seriesId
      } else {
        pubRequestInfo.groupId = this.groupId
      }
      if (yrRange) {
        pubRequestInfo.yearRange = this.yearRange
        publicationsQuery += `y:[${this.yearRange[0]} ${this.yearRange[1]}] `
      }
      const rows = []

      for (const i in data) {
        pubRequestInfo.authorId = data[i].authorId
        rows.push(
          [
            {
              // url: '/mathscinet/search/author.html?mrauthid=' + data[i].authorId,
              // url: URLGen.author({ authorId: data[i].authorId }),
              to: {
                name: 'AuthorPage',
                query: {
                  authorId: data[i].authorId,
                },
              },
              text: formatName(data[i].profileName),
              target: 'author',
              attr: {
                title: 'View profile for ' + formatName(data[i].profileName),
              },
            },
            {
              // url: '/mathscinet/search/publications.html?jourGroupId=' + this.groupId + '&pg1=INDI&s1=' + data[i].authorId + yearRange,
              // url: URLGen.publications(pubRequestInfo),
              to: {
                name: 'PublicationsSearch',
                query: {
                  query: publicationsQuery + `auid:${data[i].authorId}`,
                },
              },
              text: formatNumber(data[i].papers),
              target: 'author',
              attr: {
                title: 'Publications authored by ' + formatName(data[i].profileName) + (yrRange ? ' over the last 3 years' : ''),
              },
            },
          ]
        )
      }
      return rows
    },
    toggleAuthorsTable: function() {
      this.authorsShownLimit = !this.authorsShownLimit
    },
  },
}
</script>
